.loginBackground {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #2A3354;
  z-index: 100500;

  .inner_cont {
    width: 100vw;
    height: 100svh;
    position: fixed;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    background-color: #46527D;

    &::after {
      content: "";
      opacity: 0.3;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1;
      background-image: url("../../assets/bg.jpg");
      background-size: cover;
    }

    .logo_cont {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      .image {
        width: 50%;
        height: 200px;
        background-image: url("../../assets/Logo-1-1.svg");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    .pop__error {
      font-size: 12px;
      line-height: 15px;
      color: #ee505f;
      margin: 0px;
      position: absolute;
      right: 14px;
      top: 9px;
    }
    .goodLine{
      color:#298A31;
    }
    .errorField{ 
      border:1px solid #ee505f
    }
    .goodField{ 
      border:2px solid #298A31
    }
    .passPop{
      padding: 2px 8px;
    }

      .special_pop__error{
        color: #ee505f;
        // position: absolute;
        display: flex;
        flex-direction: column;
        /* left: 0; */
        gap: 4px;
        text-align: left;
        font-size: 14px;
        right: -15px;
        z-index: 1;
        background: #F1F8FE;
        padding: 10px 12px;
        border-radius: 4px;
        top: 33px;
      }
    .verticle_line {
      border-left: 2px solid white;
      height: 70vh;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media screen and (max-width:1023px) {
        display: none;
      }
    }

    .left_part {
      flex: 50%;

      @media screen and (max-width:1023px) {
        display: none;
      }
    }

    .right_part {
      flex: 50%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 50px;
      align-items: center;

      .upper_text_cont {
        color: white;
      }

      .form_cont {
        max-width: 400px;
        width: 100%;

        @media screen and (max-width:640px) {
          max-width: 350px;

        }

        .form {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .password,
          .username {
            position: relative;
          }
          .submitMargin{
            margin: 40px;
          }
          .submit_button {

            .action_button {
              width: 150px;
            }
          }
          .forgot{
            color: white;
            cursor: pointer;
            &:hover{
              color: #0867d7;
            }
          }
        }
      }
    }
  }
}

.background{
  background-color: rgb(0, 0, 0,0.7);
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100500;
  .forgotPopUp{
    width: 493px;
    max-height:292px;
    background-color: white;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    label{
      margin-bottom: 4px;
      color: #8692BA;
    }
    button{
      width:120px;
    }
    .pop__error {
      left: 12px;
      font-size: 12px;
      line-height: 15px;
      color: #ee505f;
      margin: 0px;
      position: absolute;
      bottom: 40px;
    }
    .header{
      background-color: #F9FAFD;
      padding: 11px 16px;
      line-height: 26px;
      font-size: 16px;

    }
    .body{
      padding: 0px 12px;

      p{
        font-size: 14px;
        margin: 21px 0 21px 0;
      }
    }
    .action_buttons{
      padding: 30px 12px 16px 12px;
      .columns {
        display: flex;
        justify-content: space-between;
      }

    }
    @media screen and (max-width: 640px) {
      width:100%;
      .body{
        p{
          // text-align: center;
          line-height: 1.6;
        }
      }

  }
  }

}

.loader {
  height: 100vh;
  width: 100%;
  background: white;
  opacity: 0.9;
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-height:500px) {
  .inner_cont{
    overflow-y: scroll;
  }
}

@media screen and  (orientation: landscape) and (max-device-height: 400px) {
  .loginBackground {
    .inner_cont {
      overflow-y: scroll;
      .right_part {
        gap:20px;
      }
    }
  }

}

.ikbs_ {
  .loginBackground {
    .inner_cont {
      background: #5499af;
      &::after {
        background-image: url("../../assets/custom-bg.jpeg");
      }
      .logo_cont {
        .image {
          background-image: url("../../assets/premium-logo.png");
        }
      }

    }
  }
}